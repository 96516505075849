@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3B82F6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body, html {
  scroll-behavior: smooth;
}